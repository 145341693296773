<script setup lang="ts">
import { TEButton, TECard, TETopLabelLayout, TETextInput } from '@ben/vite-te'

const form = useForm<{
  title: string
  description: string
  start: Date
  location: object
  location_name: string
  city: string
  organizer: string
  link: string
  organizer_email: string
  organizer_first_name: string
  zip: string
}>({
  title: '',
  description: '',
  start: new Date(),
  location: {},
  location_name: '',
  city: '',
  organizer: '',
  link: '',
  organizer_email: '',
  organizer_first_name: '',
  zip: '',
})

function gotNewLocation(event: { location: object; city: string; location_name: string }) {
  form.location = event.location
  form.city = event.city
  form.location_name = event.location_name
  disabled.value = false
}

const disabled = ref(true)

function submit() {
  disabled.value = true
  form
    .transform((data) => ({
      ...data,
      start: new Date(data.start).toISOString(),
    }))
    .post(route('demonstration.store'), {
      onError: () => {
        disabled.value = false
      },
      onSuccess: () => {
        disabled.value = false
        window.location.href = 'https://zusammen-gegen-rechts.org'
      },
    })
}
</script>

<template>
  <div>
    <div class="min-h-screen bg-gray-100 dark:bg-gray-900">
      <!-- Page Content -->
      <main class="mx-auto max-w-7xl">
        <TECard>
          <h1 class="text-2xl font-bold leading-10 text-purple-950 dark:text-purple-300">
            Demonstration eintragen
          </h1>

          <div class="flex flex-wrap">
            <div class="grid w-full grid-cols-2 gap-2 sm:grid-cols-4">
              <div class="col-span-2 sm:col-span-4">
                <h2 class="text-base font-semibold leading-7 text-gray-900 dark:text-gray-50">
                  Persönliche Infos
                </h2>

                <p class="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-300">
                  Wird NICHT veröffentlicht, dient lediglich der Kontaktaufnahme im Rahmen der
                  Aktionen
                </p>
              </div>

              <TETopLabelLayout
                name="name"
                :error="form.errors.organizer"
                class="col-span-1"
                title="Dein Name"
                required
              >
                <TETextInput v-model="form.organizer" name="name" placeholder="Name" />
              </TETopLabelLayout>

              <TETopLabelLayout
                name="first_name"
                :error="form.errors.organizer_first_name"
                class="col-span-1"
                title="Dein Vorname"
                required
              >
                <TETextInput
                  v-model="form.organizer_first_name"
                  name="first_name"
                  placeholder="Vorname"
                />
              </TETopLabelLayout>

              <TETopLabelLayout
                name="zip"
                :error="form.errors.zip"
                class="col-span-1"
                title="Deine Postleitzahl"
                required
              >
                <TETextInput v-model="form.zip" name="zip" placeholder="Deine Postleitzahl" />
              </TETopLabelLayout>

              <TETopLabelLayout
                name="email"
                :error="form.errors.organizer_email"
                class="col-span-1"
                title="Deine E-Mail"
                required
              >
                <TETextInput
                  id="email"
                  v-model="form.organizer_email"
                  name="email"
                  placeholder="E-Mail"
                  type="email"
                />
              </TETopLabelLayout>
            </div>

            <hr class="mt-4 w-full" />

            <div class="w-full">
              <h2 class="text-base font-semibold leading-7 text-gray-900 dark:text-gray-50">
                Allgemeines zur Demo
              </h2>

              <p class="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-300">
                Teile uns hier alle wichtigen Informationen zu deiner Demonstration mit.
              </p>
            </div>

            <div class="flex w-full gap-4">
              <TETopLabelLayout
                name="title"
                :error="form.errors.title"
                class="w-1/2"
                required
                title="Titel deiner Demonstration"
              >
                <TETextInput
                  id="title"
                  v-model="form.title"
                  name="title"
                  placeholder="Zusammen gegen Rechts Berlin"
                  type="text"
                />
              </TETopLabelLayout>

              <TETopLabelLayout
                name="title"
                :error="form.errors.start"
                class="w-1/2"
                required
                title="Startdatum deiner Demonstration"
              >
                <input
                  id="title"
                  v-model="form.start"
                  class="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 outline-0 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-0 focus:ring-2 focus:ring-inset focus:ring-primary-600 focus:transition-all sm:text-sm sm:leading-6 dark:bg-zinc-600 dark:text-white dark:ring-zinc-900 focus:dark:ring-primary-900"
                  name="title"
                  type="datetime-local"
                />
              </TETopLabelLayout>
            </div>

            <TETopLabelLayout
              name="link"
              class="w-full"
              :error="form.errors.link"
              title="Link zu mehr Informationen (Insta, FB etc.)"
            >
              <TETextInput id="link" v-model="form.link" name="link" />
            </TETopLabelLayout>

            <TETopLabelLayout
              name="title"
              :error="form.errors.description"
              class="w-full"
              required
              title="Beschreibung deiner Demonstration"
            >
              <textarea
                id="title"
                v-model="form.description"
                class="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 outline-0 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-0 focus:ring-2 focus:ring-inset focus:ring-primary-600 focus:transition-all sm:text-sm sm:leading-6 dark:bg-zinc-600 dark:text-white dark:ring-zinc-900 focus:dark:ring-primary-900"
                name="title"
                placeholder="Wir rufen alle auf unter dem Motto 'Zusammen gegen Rechts' zu demonstrieren.
Mit dabei ist ein Breites Bündnis aus Gewerkschaften, Parteien, Vereinen und Einzelpersonen. Wir freuen uns auf euch!"
                type="text"
              />
            </TETopLabelLayout>

            <hr class="mt-4 w-full" />

            <div class="w-full">
              <h2 class="text-base font-semibold leading-7 text-gray-900 dark:text-gray-50">
                Startpunkt der Demo
              </h2>

              <p class="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-300">
                Wo soll die Demo auf der Karte angezeigt werden - trage es hier ein.
              </p>
            </div>

            <LocationSearch @updated="gotNewLocation" />
          </div>

          <template #actions>
            <TEButton id="submit" color="purple" :disabled="disabled" @click="submit"
              >Demo eintragen</TEButton
            >
          </template>
        </TECard>
      </main>
    </div>
  </div>
</template>
